<template>
	<div>
		<v-item-group
			v-for="row in subquestions"
			:key="row.value"
			v-model="value[row.value]"
			:multiple="multiple"
			:disabled="isDisabled"
			ta
		>
			<p class="subtitle-2">{{ row.text }}</p>
			<div
                class="mb-3"
				v-for="col in answerOptionsOrder"
				:key="`${row.value}-${answerOptions[col].value}`"
			>
				<v-item
					v-slot="{ active, toggle }"
					:value="answerOptions[col].value"
				>
					<v-card
						flat
						:color="active ? 'success' : 'white'"
						:dark="active ? true : false"
						class="mw-option-col pa-4 text-center mt-2"
						transparent
						tabindex="0"
						@keydown.space="(e) => selectOption(e, toggle)"
						@click="(e) => selectOption(e, toggle)"
					>
						{{ answerOptions[col].text }}
					</v-card>
				</v-item>
			</div>
		</v-item-group>
	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "MatrixMobile",
	props: {
		value: {},
		subquestions: { type: Array },
		answerOptionsOrder: {}
	},
	computed: {
		isDisabled() {
			return this.disabled || this.question.disable_input;
		},
	},
	inject: [
		"question",
		"disabled",
		"multiple",
		"answerOptions",
	],
	methods: {
		selectOption(e,cb){
			e.preventDefault();
			this.isDisabled ? this.$emit("warn") : cb()
		},
	}
};
//
</script>
"
